import FileSaver from 'file-saver';
import { useState } from 'react';
import { IoDownload } from 'react-icons/io5';
import ErrorsModal from './ErrorsModal';
import IconWrapper from './IconWrapper';
import PendingButton from './PendingButton';

const DownloadAuctionButton = (props: any) => {
  const className = props.className || 'ms-2 mb-2';
  const auction: Auction = props.auction;
  const size = props.size || 'md';
  const [downloadPending, setDownloadPending] = useState(false);
  const [downloadError, setDownloadError] = useState<string|null>(null);

  const downloadAuction = (auction: Auction) => {
    setDownloadPending(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/auctions/get_configfile/${auction.id}`)
      .then((res) => {
        if (!res.ok) {
          if (res.status ===400)
            { return res.text().then(text => { throw new Error(text) }) }
          else {
            throw new Error(`${res.status} error: Could not find resource at server. Contact admin.`);
          }
        }
        else {
          return res.json()
        }
      })
      .then((data) => {
        const decodedConfigFile = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
        const blob = new Blob([decodedConfigFile], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, `${auction.name.replaceAll(' ','_')}.xlsx`);
        setDownloadPending(false);
        console.log('downloading original config file');
      })
      .catch((err) => {
        setDownloadPending(false);
        setDownloadError(err.message)
        console.log(err);
      });
  }

  return (
    <>
      <PendingButton
        className={className}
        variant='outline-primary'
        onClick={() => downloadAuction(auction)}
        pending={downloadPending}
        size={size}
      >
        <IconWrapper>
          <IoDownload />
          <span>Orig. Config</span>
        </IconWrapper>
      </PendingButton>
      <ErrorsModal
        show={!!downloadError}
        onHide={()=>setDownloadError(null)}
        errorsList={[downloadError]}
      />
    </>
  )
}

export default DownloadAuctionButton;