import { useEffect, useState } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import PendingButton from "../global/PendingButton";

const FileEditor = (props:any) => {
    const currentAuction: Auction = props.selectedAuction;
    // const namedBidders = props.namedBidders;

    const [newAuctionName, setNewAuctionName] = useState<string>('');
    const [newEp, setNewEp] = useState<string>('');
    const [newIncr, setNewIncr] = useState<string>('');

    const [newAuctionNameError, setNewAuctionNameError] = useState<string|null>(null);
    const [newEpError, setNewEpError] = useState<string|null>(null);
    const [newIncrError, setNewIncrError] = useState<string|null>(null);
    const [mainErrorAlert, setMainErrorAlert] = useState<string|null>(null);
    const [showMainAlert, setShowMainAlert] = useState(false);

    const [editFilePending, setEditFilePending] = useState(false);

    const handleSubmitEdit = (e: React.FormEvent<HTMLFormElement>) => {
      const form = e.currentTarget;
      let isValid = true;

      e.preventDefault();
      e.stopPropagation();

      if (form.checkValidity() === false) {
        isValid = false;
      }

      if (newAuctionName && newAuctionName.length > 256) {
        isValid = false;
        setNewAuctionNameError('Auction name too long. Must be within 256 characters.');
      }
      if (newEp && (parseInt(newEp) < 90 || parseInt(newEp) > 100)) {
        isValid = false;
        setNewEpError('EP percentage must be between 90 and 100');
      }
      if (newIncr && (parseInt(newIncr) < 1 || parseInt(newIncr) > 20)) {
        isValid = false;
        setNewIncrError('Increment percentage must be between 1 and 20');
      }

      if (isValid) {
        const editAuctionObj: EditAuction = {
          name: newAuctionName.length > 0 ? newAuctionName : undefined,
          ep_pct: newEp.length > 0 ? parseInt(newEp) : undefined,
          inc_pct: newIncr.length > 0 ? parseInt(newIncr) : undefined
        }

        setEditFilePending(true);

        console.log('submitting POST');
        fetch(`${process.env.REACT_APP_API_URL}/api/auctions/edit/${currentAuction.id}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(editAuctionObj)
        })
          .then(res => {
            if (!res.ok) {
              if (res.status ===400) {
                return res.text().then(text => { throw new Error(text) })
              }
              else {
                throw new Error(`${res.status} error: ${res.statusText}`)
              }
            }
            // else {
            //   console.log(res);
            // }
          })
          .then(data => {console.log('successfully sent as POST'); window.location.reload();})
          .catch(err => {
            console.log(err);
            setMainErrorAlert(err.message);
            setShowMainAlert(true);
            setEditFilePending(false);
          });
      }
      else {
        setMainErrorAlert('Invalid fields found');
        setShowMainAlert(true);
      }
    }

    useEffect(() => {
      setShowMainAlert(false);
    }, []);

    return (
      <>
        {showMainAlert && <Alert
          variant='danger'
          onClose={() => setShowMainAlert(false)}
          dismissible
          >
          <Alert.Heading>Error</Alert.Heading>
          <p>
            {mainErrorAlert}
          </p>
        </Alert>
        }
        <Alert variant='info'>
          <strong>Note:</strong> Leave fields blank to keep them unchanged.
        </Alert>
        <Form onSubmit={(e)=>handleSubmitEdit(e)}>
          <fieldset>
            <Form.Group>
              <Form.Label>
                Current Auction Name: <strong>{currentAuction.name}</strong>
              </Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='addon-name'>Modify to:</InputGroup.Text>
                <Form.Control
                  placeholder='New auction name'
                  aria-label='Auction name'
                  aria-describedby='addon-name'
                  value={newAuctionName}
                  onChange={e => setNewAuctionName(e.target.value)}
                  isValid={!!newAuctionName && !newAuctionNameError}
                  isInvalid={newAuctionNameError ? !!newAuctionNameError : undefined}
                  />
                <Form.Control.Feedback type='invalid'>
                  {newAuctionNameError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {!currentAuction.ended && <>
            <Form.Group>
              <Form.Label>
                Current EP Percentage: <strong>{currentAuction.ep_pct}</strong>
              </Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='addon-ep-pct'>Modify to:</InputGroup.Text>
                <Form.Control
                  placeholder='New EP percentage'
                  aria-label='New EP percentage'
                  aria-describedby='addon-ep-pct'
                  value={newEp}
                  type='number'
                  min={90}
                  max={100}
                  onChange={e => setNewEp(e.target.value)}
                  isValid={!!newEp && parseInt(newEp) <= 100 && parseInt(newEp) >= 90 && !newEpError}
                  isInvalid={newEpError ? !!newEpError : (parseInt(newEp) > 100 || parseInt(newEp) < 90)}
                  />
                <Form.Control.Feedback type='invalid'>
                  {newEpError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Current Incr. Percentage: <strong>{currentAuction.inc_pct}</strong>
              </Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='addon-inc-pct'>Modify to:</InputGroup.Text>
                <Form.Control
                  placeholder='New incr. percentage'
                  aria-label='New incr. percentage'
                  aria-describedby='addon-inc-pct'
                  value={newIncr}
                  type='number'
                  min={1}
                  max={20}
                  onChange={e => setNewIncr(e.target.value)}
                  isValid={!!newIncr && parseInt(newIncr) <= 20 && parseInt(newIncr) >= 1 && !newIncrError}
                  isInvalid={newIncrError ? !!newIncrError : (parseInt(newIncr) > 20 || parseInt(newIncr) < 1)}
                  />
                <Form.Control.Feedback type='invalid'>
                  {newIncrError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group></>
            }
          </fieldset>
          <div className='d-flex justify-content-center'>
            <PendingButton
              variant='primary'
              type='submit'
              value='Submit'
              pending={editFilePending}
              inputType={true}
            />
          </div>
        </Form>
      </>
    );
};

FileEditor.displayName = 'FileEditor';

export default FileEditor;

