import { useEffect } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Dropdown, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { userStringTupleSort } from '../../Helpers';
import NamedBidder from '../global/NamedBidder';
import logo from '../../nesglobe.png'

const Welcome = (props: any) => {
  const userFetch = props.userFetch;
  const title = props.title;
  const handleLogin = props.handleLogin;

  const users: [string, string][] = userFetch.data;
  const isLoadingUsers = userFetch.isPending;
  const userFetchError = userFetch.error;

  const renderTooltip = (props:any) => (
    <Tooltip id="button-tooltip" {...props}>
      View Regions, Products, Named Bidders, etc.
    </Tooltip>
  );

  useEffect(()=>{
    console.log(userFetchError);
  }, [userFetchError]);

  return (
    <div className='welcome'>
      <div className='bg-light p-5 rounded-lg w-75 mt-5 mx-auto text-start'>
	<img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
        <h1 className='display-4 fs-2'>Welcome to the NE&S<br /><strong>{title}</strong></h1>
          <ButtonToolbar>
            <ButtonGroup className='m-2'>
              <Dropdown>
                <Dropdown.Toggle variant="primary" disabled={isLoadingUsers || userFetchError || (users && users.length === 0)}>
                  {isLoadingUsers && <><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                    </>}
                  {userFetchError && userFetchError.length < 256 && userFetchError}
                  {userFetchError && userFetchError.length >= 256 && 'Failed to connect to server'}
                  {users && users.length === 0 && 'No bidders available'}
                  {users && users.length > 0 && 'Login as Bidder'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {users && users.length > 0 && users.sort(userStringTupleSort).map((user: [string, string]) => (
                    <Dropdown.Item as={Link} to='/web/auction-cp' onClick={() => handleLogin(user)} key={user[0]}>
                      {(user[0]==='BEL'||user[0]==='BR'||user[0]==='BP'||user[0]==='COG'||user[0]==='COM'||user[0]==='ECO'||user[0]==='IC'||user[0]==='IR'||user[0]==='RO'||user[0]==='SSI'||user[0]==='ST'||user[0]==='SOG'||user[0]==='SS'||user[0]==='TB'||user[0]==='TEL'||user[0]==='TC'||user[0]==='VF'||user[0]==='VN'||user[0]==='VID'||user[0]==='WI'||user[0]==='XP') ? <NamedBidder name={user[1]} abbr={user[0]} /> : <>{user[1]}</>}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
            {!userFetchError && !isLoadingUsers &&
              (
                <ButtonGroup className='m-2'>
                  <Link to='/web/auction-cp'>
                    <Button variant='outline-primary' onClick={() => handleLogin(null, true)}>
                      Login as Admin
                    </Button>
                  </Link>
                </ButtonGroup>
                )
            }
            <ButtonGroup className='m-2'>
              <Link to='/web/static'>
                <OverlayTrigger
                  placement='bottom'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                  >
                  <Button variant='outline-secondary'>
                    View Static Data
                  </Button>
                </OverlayTrigger>
              </Link>
            </ButtonGroup>
          </ButtonToolbar>
      </div>
    </div>
  );
};

Welcome.displayName = 'Welcome';

export default Welcome;
