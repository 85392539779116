import { useState, useEffect } from 'react';

const useFetch = (url:string) => {
  const [data, setData] = useState<any | null>(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(url)
      .then(res => {
        if(!res.ok) {
          if (res.status !== 404) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status}: ${res.statusText}`)
          }
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setData(data);
        setIsPending(false);
        // console.log('fetched from ' + url);
      })
      .catch(err => {
        setIsPending(false);
        setError(err.message);
      })
  }, [url]);

  return { data, isPending, error }
}

export default useFetch;