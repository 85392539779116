import { useEffect, useState } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import { formatDateCode } from "../../Helpers";
import PendingButton from "../global/PendingButton";

const CopyAuctionPane = (props: any) => {
  const selectedAuction: Auction = props.selectedAuction;
  const [copyRoundNum, setCopyRoundNum] = useState(selectedAuction.round_num);
  const [copyRoundNumError, setCopyRoundNumError] = useState<string|null>();
  const [newName, setNewName] = useState<string>(selectedAuction.name + ' COPY: ' + formatDateCode(Date.now()));
  const [namingError, setNamingError] = useState<string|null>();
  const [mainErrorAlert, setMainErrorAlert] = useState<string|null>(null);
  const [showMainAlert, setShowMainAlert] = useState(false);
  const [copyPending, setCopyPending] = useState(false);

  const handleSubmitDuplicateAuction = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    let isValid = true;
    if (form.checkValidity() === false) {
      isValid = false;
    }

    e.preventDefault();

    isValid = validate();

    if (isValid) {
      const copiedAuction = {
        source_auction_id: selectedAuction.id,
        new_name: newName,
        until_round_num: copyRoundNum
      };

      console.log('attempting to POST:');
      setCopyPending(true);

      fetch(`${process.env.REACT_APP_API_URL}/api/auctions/copy`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(copiedAuction)
      })
        .then(res => {
          if (!res.ok) {
            if (res.status ===400) {
              return res.text().then(text => { throw new Error(text) })
            }
            else {
              throw new Error(`${res.status} error: ${res.statusText}`)
            }
          }
        })
        .then(data => {console.log('successfully sent as POST'); window.location.reload();})
        .catch(err => {
          setMainErrorAlert(err.message);
          setShowMainAlert(true);
          setCopyPending(false);
        });
    }
    else {
      e.stopPropagation();
      setMainErrorAlert('Invalid fields found');
    }
  }

  const validate = () => {
    if (copyRoundNum>= 1 && copyRoundNum <= selectedAuction.round_num) {
      return true
    }
    else {
      setCopyRoundNumError('Round number must be between 1 and ' + selectedAuction.round_num + ', inclusive.');
      return false
    }
  }

  useEffect(() => {
    setShowMainAlert(false);
  }, []);

    return (
      <>
        {showMainAlert && <Alert
          variant='danger'
          onClose={() => setShowMainAlert(false)}
          dismissible
          >
          <Alert.Heading>Error</Alert.Heading>
          <p>
            {mainErrorAlert}
          </p>
        </Alert>
        }
        <Form onSubmit={e=>handleSubmitDuplicateAuction(e)}>
          <Form.Group>
            <Form.Label>
              Enter a new name for the duplicated auction.
            </Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text id='addon-new-name'>New name:</InputGroup.Text>
              <Form.Control
                placeholder={selectedAuction.name + ' COPY: ' + formatDateCode(Date.now())}
                aria-label='New name'
                aria-describedby='addon-new-name'
                value={newName}
                onChange={e => setNewName(e.target.value)}
                isValid={!namingError}
                isInvalid={namingError ? !!namingError : undefined}
                />
              <Form.Control.Feedback type='invalid'>
                {namingError}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Enter the round number up to which the auction <strong>{selectedAuction.name}</strong> should be duplicated. The auction will be ready accept bids at the specified round number.<br/>
              {selectedAuction.round_num > 2 ? `The round number must be between 1 and ${selectedAuction.round_num}, inclusive.` : ''} Duplicating at round 1 is the same as creating a new auction with the same configuration and no bids.
            </Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text id='addon-copy-round-num'>Round:</InputGroup.Text>
              <Form.Control
                placeholder={(selectedAuction.round_num-1).toString()}
                aria-label='Copy round number'
                aria-describedby='addon-copy-round-num'
                type='number'
                min={1}
                max={selectedAuction.round_num}
                step={1}
                value={copyRoundNum}
                onChange={e => setCopyRoundNum(parseFloat(e.target.value))}
                isValid={!copyRoundNumError&&copyRoundNum<=selectedAuction.round_num&&copyRoundNum>=1}
                isInvalid={!!copyRoundNumError || copyRoundNum>selectedAuction.round_num || copyRoundNum < 1 || (copyRoundNum!==Math.floor(copyRoundNum))}
                />
              <Form.Control.Feedback type='invalid'>
                {copyRoundNumError}
              </Form.Control.Feedback>
            </InputGroup>
            <div className='d-flex justify-content-center'>
              <PendingButton
                variant='primary'
                type='submit'
                value='Submit'
                asType='input'
                pending={copyPending}
                inputType={true}
              />
            </div>
          </Form.Group>
        </Form>
      </>
    );
};

CopyAuctionPane.displayName = 'CopyAuctionPane';

export default CopyAuctionPane;
