import { useEffect, useMemo, useState } from "react";
import { Alert, ButtonGroup, Card, Col, Container, Form, InputGroup, ListGroup, Row } from "react-bootstrap";

import { HiDesktopComputer, HiOutlineSearch, HiOutlineStar } from "react-icons/hi";
import { formatNumber, initPkgSort } from "../../Helpers";
import MySpinner from "../../MySpinner";
import useFetch from "../../useFetch";
import DownloadAuctionButton from "../global/DownloadAuctionButton";
import IconWrapper from "../global/IconWrapper";
import NamedBidder from "../global/NamedBidder";
import TooltipIcon from "../global/TooltipIcon";
import DemandCurve from "./DemandCurve";

const ParentAuctionSummary = (props: any) => {
  const auction: Auction = props.auction;
  const { data: bidders, isPending: biddersLoading, error: biddersError } = useFetch(process.env.REACT_APP_API_URL + '/api/bidders/list/' + auction.id);
  const { data: products, isPending: productsLoading, error: productsError } = useFetch(`${process.env.REACT_APP_API_URL}/api/products/list`);

  const [selectedBidder, setSelectedBidder] = useState<Bidder|null>(null);
  const [selectedBidderInitPkgSet, setSelectedBidderInitPkgSet] = useState<ABConfig[]|null>(null);
  const [selectedBidderInitPkgSetLoading, setSelectedBidderInitPkgSetLoading] = useState(false);
  const [selectedBidderInitPkgSetError, setSelectedBidderInitPkgSetError] = useState<string|null>(null);

  const [selectedInitPkg, setSelectedInitPkg] = useState<ABConfig|null>(null);
  const [initPkgSearch, setInitPkgSearch] = useState('');
  const [foundInitPkgs, setFoundInitPkgs] = useState<ABConfig[]>([]);

  const numInitPkgValues = useMemo(()=>{
    if (selectedInitPkg) {
      return [selectedInitPkg.max_dmp_q1, selectedInitPkg.max_dmp_q2, selectedInitPkg.max_dmp_q3, selectedInitPkg.max_dmp_q4, selectedInitPkg.max_dmp_q5].filter(x=>!!x).length
    }
    else {
      return 0
    }
  }, [selectedInitPkg]);

  const fetchInitPkgsByBidder = (auctionId:number, bidderId:number) => {
    setSelectedBidderInitPkgSetLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/abconfigs/get/${auction.id}?bidder_id=${bidderId}`)
      .then(res => {
        if(!res.ok) {
          if (res.status ===400) {
            return res.text().then(text => { throw new Error(text) })
          }
          else {
            throw new Error(`${res.status} error: ${res.statusText}`);
          }
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setSelectedBidderInitPkgSet(data);
        console.log(`fetched init pkgs for Auction ${auctionId} Biddeer ${bidderId}`);
        setSelectedBidderInitPkgSetLoading(false);
      })
      .catch(err => {
        setSelectedBidderInitPkgSetLoading(true);
        setSelectedBidderInitPkgSetError(err.message);
      });
  }

  const filterInitPkgs: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const keyword: string = e.currentTarget.value;

    setInitPkgSearch(keyword);

    if (selectedBidderInitPkgSet) {
      let results: ABConfig[];
      if (keyword !== '') {
          results = selectedBidderInitPkgSet.filter((ip: ABConfig) => {
            const product: string = products[ip.product_code].open?.name || products[ip.product_code].sap?.name;
            return product.startsWith(keyword.toLowerCase()) || product.toLowerCase().includes(keyword.toLowerCase());
          });
      }
      else {
        results = selectedBidderInitPkgSet;
      }
      setFoundInitPkgs(results.sort(initPkgSort));
    }
  }

  useEffect(()=>{
    if (selectedBidderInitPkgSet) {
      setFoundInitPkgs(selectedBidderInitPkgSet.sort(initPkgSort));
    }
  }, [selectedBidderInitPkgSet]);

  useEffect(()=>{
    if (auction && selectedBidder) {
      fetchInitPkgsByBidder(auction.id, selectedBidder.id);
    }
  }, [selectedBidder]);

  return (
    <Container fluid>
      <Card
        className='parentAuctionSummaryCard text-center px-3 py-2 mt-2 mb-2 d-flex flex-column'
        style={{ backgroundColor: 'rgba(87, 46, 106, 0.1)' }}
        >
        <div className='d-flex mb-1 mb-lg-2'>
          <div className='flex-grow-1'>
            <h5 className='text-start my-2 me-2'>
              <span className='me-3'>Parent Auction Info: <strong>{auction.name}</strong></span>
            </h5>
          </div>
          <div>
            <ListGroup horizontal>
              <ListGroup.Item className='px-2 my-1'>
                <small>
                  <span className='me-2 align-middle'>Increment:</span>
                  <span className='fw-bold align-middle'>{auction.inc_pct} %</span>
                </small>
              </ListGroup.Item>
              <ListGroup.Item className='px-2 my-1'>
                <small>
                  <span className='me-2 align-middle'>Spectrum Cap:</span>
                  <span className='fw-bold align-middle'>{auction.spectrum_cap} blocks</span>
                </small>
              </ListGroup.Item>
              <ListGroup.Item className='px-2 my-1'>
                <ButtonGroup>
                  <DownloadAuctionButton size='sm' className='m-0' auction={auction} />
                </ButtonGroup>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
        <div className='d-flex align-self-stretch px-0'>
          <div style={{ width: '15%' }} className='align-self-stretch me-3'>
            <h6 className='text-start'>Bidders</h6>
            <ListGroup className='biddersListGroup overflow-auto'>
              {biddersLoading && <MySpinner />}
              {!biddersLoading && !biddersError && bidders.map((b: Bidder)=>{
                return <ListGroup.Item key={b.id} action onClick={()=>{setSelectedBidder(b); setSelectedInitPkg(null);}} active={!!selectedBidder && selectedBidder.id===b.id}>
                  <IconWrapper>
                    <span className='text-start fw-light fs-6'>
                      {b.is_named && <NamedBidder name={b.name} abbr={b.abbr} />}
                      {!b.is_named && <>{b.name}</>}
                    </span>
                    {b.autobidder && <TooltipIcon id={`${b.id}-autobidder`} text='Autobidder'><HiDesktopComputer /></TooltipIcon>}
                    {b.is_named && <TooltipIcon id={`${b.id}-named`} text='Bidder with 3500 MHz holdings'><HiOutlineStar /></TooltipIcon>}
                  </IconWrapper>
                </ListGroup.Item>
              })}
            </ListGroup>
          </div>
          <div className='align-self-stretch me-3 flex-grow-1'>
            <Row className='row-cols-2 mb-0'>
              <Col sm={12} lg={4} className='text-start'>
                <h6>Bidder Info {selectedBidder && <> (<span className='fw-bolder'>{selectedBidder.name}</span>)</>}</h6>
              </Col>
              {selectedBidder && <>
                <Col sm={12} lg={4} className='text-center'>
                  <h6 className='fw-light'><small>Total Budget: <span className='fw-bold'>{formatNumber(selectedBidder.budget, true)}</span></small></h6>
                </Col>
                <Col sm={12} lg={4} className='flex-shrink-1 text-end'>
                  <h6 className='fw-light'><small>Initial EP: <span className='fw-bold'>{formatNumber(selectedBidder.init_ep)} EP</span></small></h6>
                </Col>
              </>}
            </Row>
            {selectedBidder &&
              <>
                {selectedBidder.autobidder && !selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && !productsLoading && !productsError && selectedBidderInitPkgSet && products &&
                  <div className="mt-3 mb-1">
                    <InputGroup>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        <IconWrapper>
                          <HiOutlineSearch />
                        </IconWrapper>
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Search"
                        aria-describedby="inputGroup-sizing-sm"
                        value={initPkgSearch}
                        onChange={filterInitPkgs}
                        type='search'
                      />
                    </InputGroup>
                  </div>
                }
                {selectedBidder.autobidder && <ListGroup className='overflow-auto selectedAutoBidderInitPkgsListGroup'>
                  {(selectedBidderInitPkgSetLoading || productsLoading) && <MySpinner />}
                  {!selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && !productsLoading && !productsError && selectedBidderInitPkgSet && products && foundInitPkgs.map((ip: ABConfig)=>{
                      return <ListGroup.Item key={ip.product_code} action onClick={()=>setSelectedInitPkg(ip)} active={!!selectedInitPkg && selectedInitPkg===ip} className='fw-light text-start'><span className='me-3'>{products[ip.product_code].open?.name || products[ip.product_code].sap?.name}</span></ListGroup.Item>
                    })}
                  </ListGroup>
                }
                {selectedBidder.autobidder && !selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && (!selectedBidderInitPkgSet || (selectedBidderInitPkgSet && selectedBidderInitPkgSet.length === 0)) && <Alert className='p-2 alert-purple'><small>No initial package lots available.</small></Alert>}
                {!selectedBidder.autobidder && <Alert className='p-2 alert-purple'><small>No initial package lots available: not an autobidder.</small></Alert>}
                {!productsLoading && productsError && selectedBidder.autobidder && !selectedBidderInitPkgSetLoading && !selectedBidderInitPkgSetError && selectedBidderInitPkgSet && selectedBidderInitPkgSet.length > 0 && <Alert variant='danger' className='p-2'>{productsError}</Alert>}
              </>
            }
            {!selectedBidder && <Alert className='alert-purple'><small>No bidder selected.<br/>Select bidder at left.</small></Alert>}
          </div>
          <div style={{ width: '33%' }} className='align-self-stretch'>
            <h6 className='text-start'>Autobidder Initial Package {selectedInitPkg && products && <> (<span className='fw-bolder'>{products[selectedInitPkg.product_code].open?.name || products[selectedInitPkg.product_code].sap?.name}</span>)</>}</h6>
            {selectedInitPkg &&
            <>
              <Row as='dl' className='row-cols-2 mb-0'>
                <Col as='dt' className='fw-light text-start'>
                  <small>Quantity:</small>
                </Col>
                <Col as='dd' className='text-end mb-0'>
                  {selectedInitPkg.quantity ?
                    <small><span className='fw-bold'>{selectedInitPkg.quantity}</span> blocks</small>
                    :
                    <small className='fw-light'>Unspecified</small>
                  }
                </Col>
              </Row>
              <Row as='dl' className='row-cols-2 mb-0'>
                <Col as='dt' className='fw-light text-start'>
                  <small>Priority:</small>
                </Col>
                <Col as='dd' className='text-end mb-0'>
                  {selectedInitPkg.priority ?
                    <small className='fw-bold'>{selectedInitPkg.priority}</small>
                    :
                    <small className='fw-light'>Unspecified</small>
                  }
                </Col>
              </Row>
              <Row as='dl' className='row-cols-2 mb-0'>
                <Col as='dt' className='fw-light text-start'>
                  <small>Max Price per Block:</small>
                </Col>
                <Col as='dd' className='text-end mb-0'>
                  {selectedInitPkg.max_price ?
                    <small className='fw-bold'>{formatNumber(selectedInitPkg.max_price, true)}</small>
                    :
                    <small className='fw-light'>Unspecified</small>
                  }
                </Col>
              </Row>
              <Row as='dl' className='row-cols-2 mb-0'>
                <Col as='dt' className='fw-light text-start'>
                  <small>Product Budget:</small>
                </Col>
                <Col as='dd' className='text-end mb-0'>
                  {selectedInitPkg.budget ?
                    <small className='fw-bold'>{formatNumber(selectedInitPkg.budget, true)}</small>
                    :
                    <small className='fw-light'>Unspecified</small>
                  }
                </Col>
              </Row>
              <Row className='mt-2 mb-0'>
                <Col>
                  <small>Demand Curve</small>
                </Col>
              </Row>
              <Row>
                <Col>
                  {numInitPkgValues>0 && numInitPkgValues<3 &&
                  <Alert className="alert-purple">
                    <small>
                      Insufficient $/MHz/pop. values specified for graph.<br/>
                      {selectedInitPkg.max_dmp_q1 && <>q1: <strong>{selectedInitPkg.max_dmp_q1}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q2 && <>q2: <strong>{selectedInitPkg.max_dmp_q2}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q3 && <>q3: <strong>{selectedInitPkg.max_dmp_q3}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q4 && <>q4: <strong>{selectedInitPkg.max_dmp_q4}</strong>&nbsp;</>}
                      {selectedInitPkg.max_dmp_q5 && <>q5: <strong>{selectedInitPkg.max_dmp_q5}</strong>&nbsp;</>}
                    </small>
                  </Alert>
                  }
                  {
                    numInitPkgValues>=3 && <>
                      <DemandCurve
                        demandCurveData={
                        [
                          {label: 'q1', value: selectedInitPkg.max_dmp_q1},
                          {label: 'q2', value: selectedInitPkg.max_dmp_q2},
                          {label: 'q3', value: selectedInitPkg.max_dmp_q3},
                          {label: 'q4', value: selectedInitPkg.max_dmp_q4},
                          {label: 'q5', value: selectedInitPkg.max_dmp_q5}
                        ]
                        }
                      />
                    </>
                  }
                  {numInitPkgValues===0 && <Alert className="alert-purple"><small>No max $/MHz/pop. values specified for this product.</small></Alert>}
                </Col>
              </Row>
            </>
            }
            {!selectedInitPkg && <Alert className="alert-purple"><small>No initial package selected.<br/>Select initial package at left (if available).</small></Alert>}
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default ParentAuctionSummary;