import { useEffect, useState } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import PendingButton from "../global/PendingButton";

const VariationEditor = (props:any) => {
  const variation: Variation = props.variation;

    const [newVariationName, setNewVariationName] = useState<string>('');

    const [newVariationNameError, setNewVariationNameError] = useState<string|null>(null);
    const [mainErrorAlert, setMainErrorAlert] = useState<string|null>(null);
    const [showMainAlert, setShowMainAlert] = useState(false);

    const [EditVariationPending, setEditVariationPending] = useState(false);

    const handleSubmitEdit = (e: React.FormEvent<HTMLFormElement>) => {
      const form = e.currentTarget;
      let isValid = true;

      e.preventDefault();
      e.stopPropagation();

      if (form.checkValidity() === false) {
        isValid = false;
      }

      if (newVariationName && newVariationName.length > 256) {
        isValid = false;
        setNewVariationNameError('Variation name too long. Must be within 256 characters.');
      }

      if (isValid) {
        const editVariationObj: EditVariation = {
          name: newVariationName.length > 0 ? newVariationName : undefined
        }

        setEditVariationPending(true);

        console.log('submitting POST');
        fetch(`${process.env.REACT_APP_API_URL}/api/variations/edit/${variation.id}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(editVariationObj)
        })
          .then(res => {
            if (!res.ok) {
              if (res.status ===400) {
                return res.text().then(text => { throw new Error(text) })
              }
              else {
                throw new Error(`${res.status} error: ${res.statusText}`)
              }
            }
            // else {
            //   console.log(res);
            // }
          })
          .then(data => {console.log('successfully sent as POST'); window.location.reload();})
          .catch(err => {
            console.log(err);
            setMainErrorAlert(err.message);
            setShowMainAlert(true);
            setEditVariationPending(false);
          });
      }
      else {
        setMainErrorAlert('Invalid fields found');
        setShowMainAlert(true);
      }
    }

    useEffect(() => {
      setShowMainAlert(false);
    }, []);

    return (
      <>
        {showMainAlert && <Alert
          variant='danger'
          onClose={() => setShowMainAlert(false)}
          dismissible
          >
          <Alert.Heading>Error</Alert.Heading>
          <p>
            {mainErrorAlert}
          </p>
        </Alert>
        }
        <Form onSubmit={(e)=>handleSubmitEdit(e)}>
          <fieldset>
            <Form.Group>
              <Form.Label>
                Current Variation Name: <strong>{variation.name}</strong>
              </Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='addon-name'>Modify to:</InputGroup.Text>
                <Form.Control
                  placeholder='New Variation name'
                  aria-label='Variation name'
                  aria-describedby='addon-name'
                  value={newVariationName}
                  onChange={e => setNewVariationName(e.target.value)}
                  isValid={!!newVariationName && !newVariationNameError}
                  isInvalid={newVariationNameError ? !!newVariationNameError : undefined}
                  />
                <Form.Control.Feedback type='invalid'>
                  {newVariationNameError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </fieldset>
          <div className='d-flex justify-content-center'>
            <PendingButton
              variant='primary'
              type='submit'
              value='Submit'
              pending={EditVariationPending}
              inputType={true}
            />
          </div>
        </Form>
      </>
    );
};

VariationEditor.displayName = 'VariationEditor';

export default VariationEditor;

